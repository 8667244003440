#hero {
  /*background-image: url('https://via.placeholder.com/1500x800'); /* Replace with actual image */
  /* background-size: cover; */
  /* background-position: center; */
  color: #f50d0d;
  padding: 50px 0;
  text-align: center;
  margin: 0;
  /* background-color: rgb(71, 41, 41); */
}
#hero h1 {
  font-size: 3rem;
}
#hero p {
  font-size: 1.5rem;
}