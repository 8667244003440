
.productCard {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s; /* Add box-shadow transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Set box-shadow */
    margin:20px 50px;
    padding: 10px;
  }
  .productCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Change box-shadow on hover */
  }
  .productCard-img-top {
    object-fit: cover;
    width: 100px; /* Set width to 100px */
    height: 200px; /* Set height to 200px */
   
  }
  .productCard-body {
    background-color: #f8f9fa;
  }

  .section-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .contact-section {
    background-color: #FFA500; /* Orange background color */
    padding: 20px 0; /* Adjust padding as needed */
    margin-top: 50px; /* Adjust margin as needed */
  }

  .company-info {
    padding: 10px; /* Adjust padding as needed */
    height: auto;
  }
  
  .contact-form {
    background-color: #fff; /* White background color for forms and information */
    padding: 10px; /* Adjust padding as needed */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
    height: auto;
  }
  
  .contact-form .form-group {
    margin-bottom: 50px; /* Vertical space between form groups */
  }
  
  .contact-form h2 {
    margin-bottom: 30px; /* Space between header and form */
  }
  
  .company-info h4 {
    margin-bottom: 20px; /* Space between headers and paragraphs */
  }

  /* Media query for screens smaller than 768px (phones) */
@media (max-width: 767.98px) {
  .productCard
  {
    margin:5px;
  }

}