.CarouselImage
{
    height: 200px;
    width: 100;
}

@media (max-width: 767.98px) {
    /* .CarouselImage
    {
        height: 100vh;
        width: 100;
    } */
    .carouselHeading
    {
        font-size: 20px;
        font-weight: bold;
    }
    .carouselPara
    {
       font-size: 12px;
    }
  }